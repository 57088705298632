import React, { forwardRef, memo } from "react";
import styles from "./styles.module.css";
import { twMerge } from "tailwind-merge";

export type ButtonProps = React.ComponentPropsWithoutRef<"button"> & {
  variant?: "filled" | "outline" | "flat";
  color?: "accent" | "neutral" | "failure" | "warning" | "success";
  loading?: boolean;
};

export const Button = memo(
  forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        variant = "filled",
        color = "accent",
        loading = false,
        children,
        className,
        ...rest
      },
      ref
    ) => (
      <button
        {...rest}
        ref={ref}
        disabled={loading || rest.disabled}
        className={twMerge(
          "select-none flex flex-row gap-1 items-center justify-center rounded-md bg-transparent font-medium text-sm py-2 px-4 disabled:opacity-50 focus:opacity-80 hover:opacity-90",
          styles[`${variant}-${color}`],
          className
        )}
      >
        {!!loading ? <i className="ri-loader-2-line animate-spin" /> : children}
      </button>
    )
  )
);
